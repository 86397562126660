
import * as React from 'react';

import MenuDefinition from './menu';
import { NavLink } from './links';

import { LanguageContext } from '../contexts/Language';

const MenuItems = ({location}) => {

    const language = React.useContext(LanguageContext);
    const localisedMenu = MenuDefinition[language || 'en'];

    const menuItems = localisedMenu.map(menuItem =>
        <NavLink
            text={menuItem.text}
            href={menuItem.href}
            current={location.pathname.startsWith(menuItem.href)}
            key={menuItem.text}
        />
    );

    return (<nav>{menuItems}</nav>);
}

export default MenuItems;
