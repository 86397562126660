import React from 'react';
import MenuItems from './nav';
import { HomeLink, SocialLinks } from './links';
import { LanguageProvider } from '../contexts/Language';
import Img from 'gatsby-image';
import './style/layout.css';
import './style/nav.css';
import './style/content.css';
import './style/theme.css';
import { FaBars } from 'react-icons/fa';
import  { MdClose } from 'react-icons/md';

const MobileNavButton = ({mobileNavIsOpen, toggleMobileNav}) => {

    return (
        <span className={'mobileNavButton'} onClick={toggleMobileNav} onKeyPress={toggleMobileNav} role={'button'} tabIndex={0}>
            {mobileNavIsOpen ? <MdClose /> : <FaBars />}
        </span>
    )
}

const Layout = ({ location, title, children, banner }) => {

    const [ mobileNavIsOpen, setMobileNavIsOpen ] = React.useState(false);
    const toggleMobileNav = () => setMobileNavIsOpen(!mobileNavIsOpen);

    const Banner = banner ? () => <Img fluid={banner.fluid} /> : () => <div>&nbsp;</div>;

    return (
        <LanguageProvider location={location}>
            <div className={'container'}>


                <div className={'nav'}>
                    <div>
                        <div className={'navTitle'}>
                            <HomeLink />
                        </div>
                    </div>
                    <div className={'navContainer'}>
                        <div className={'navItems'}>
                            <MenuItems location={location} />
                        </div>
                    </div>
                </div>

                <div className={'mobileNav'}>
                    <div className={'mobileNavTitle'}>
                        <HomeLink />
                        <MobileNavButton mobileNavIsOpen={mobileNavIsOpen} toggleMobileNav={toggleMobileNav} />
                    </div>
                    <div className={'mobileNavContainer' + (mobileNavIsOpen ? ' open' : '')}>
                        <div className={'mobileNavItems'}>
                            <MenuItems location={location} />
                        </div>
                    </div>
                </div>


                <div className={'body'}>
                    <div className={'banner'}>
                        <Banner />
                    </div>
                    <div className={'content'}>
                        <main>
                            <article>
                                {children}
                            </article>
                        </main>
                    </div>

                </div>

                <div className={'footer'}>
                        <footer>
                            <SocialLinks />
                            {/* © 2020 Kvednaflåt */}
                            {/* <br/><FacebookLink /> */}
                        </footer>
                    </div>
            </div>
        </LanguageProvider>
    )
}

export default Layout
