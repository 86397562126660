import * as React from 'react';

import { Link } from 'gatsby';

// https://react-icons.github.io/react-icons/
import {
    FaFacebookSquare,
    FaRegEnvelope,
    FaInstagramSquare,
    FaMapMarkerAlt,
} from 'react-icons/fa';

export const HomeLink = () => (
    <h3 className={'homelink'}>
        <SiteLink href={'/'} text={'Kvednaflåt'} />
    </h3>
);

export const NavLink = (props) => (
    <div>
        <SiteLink {...props} />
    </div>
);

export const SiteLink = ({ href, text, current }) => (
    <Link
        className={current ? 'active navlink' : 'navlink'}
        to={href}
    >
        {text}
    </Link>
);

export const FacebookLink = () => (
    <div>
        <a
            href={'https://facebook.com/'}
            target={'_blank'}
            rel='noopener noreferrer'
        >
            <FaFacebookSquare fontSize={40} />
        </a>
    </div>
);

export const InstaLink = () => (
    <div>
        <a
            href={'https://instagram.com/'}
            target={'_blank'}
            rel='noopener noreferrer'
        >
            <FaInstagramSquare fontSize={40} />
        </a>
    </div>
);

export const ContactLink = () => (
    <div className={'contactLinks'}>
        <div><span className={'icon'}><FaRegEnvelope/></span><span>booking@kvednaflaat.org</span></div>
        <div><span className={'icon'}><FaMapMarkerAlt /></span><span>Møgedalsveien 394, 4376 Helleland</span></div>
    </div>
)

export const SocialLinks = () => (
    <div className={'socialLinks'}>
        <FacebookLink />
        <ContactLink />
        <InstaLink />
    </div>
)