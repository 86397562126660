export default {
    en: [
        {
            text: 'home',
            href: '/en/home',
        },
        {
            text: 'booking',
            href: '/en/booking',
        },
        {
            text: 'facilities',
            href: '/en/facilities',
        },
        {
            text: 'location',
            href: '/en/directions',
        },
        {
            text: 'contact',
            href: '/en/contact',
        },
        {
            text: 'wwoof',
            href: '/en/wwoof',
        },
        {
            text: 'norsk',
            href: '/no/home',
        },
    ],
    no: [
        {
            text: 'start',
            href: '/no/home',
        },
        {
            text: 'utleie',
            href: '/no/booking',
        },
        {
            text: 'anlegg',
            href: '/no/facilities',
        },
        {
            text: 'retninger',
            href: '/no/directions',
        },
        {
            text: 'kontakt',
            href: '/no/contact',
        },
        {
            text: 'english',
            href: '/en/home',
        },
    ],
}
